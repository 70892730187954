import { Injectable } from '@angular/core';
import {
  BaseApiResponse,
  ErrorResponse,
  ListData,
} from '../models/bases/base-api-response';

@Injectable({
  providedIn: 'root',
})
export class Utils {
  private mongoIDReg = /^[a-fA-F0-9]{24}$/;
  constructor() {}

  isMongoDbID(value: string): boolean {
    return this.mongoIDReg.test(value);
  }

  isErrorResponse(response: BaseApiResponse<any>): response is ErrorResponse {
    return response.errors !== undefined;
  }

  isListData<T>(response: any): response is ListData<T> {
    return response.list !== undefined;
  }

  isTypeOf<T>(response: any): response is T {
    return typeof response != 'undefined' && response.error == undefined;
  }

  isData<T>(response: any): response is T {
    return response && !response.errors && !response.list;
  }

  toKhmerNumericText(numberOrString: string | number) {
    if (!numberOrString) {
      return '០';
    }
    const splittedChars = numberOrString.toString().split('');
    let finalResult: string = '';
    splittedChars.forEach((char: string) => {
      switch (char) {
        case '0':
          finalResult = finalResult + '០';
          break;
        case '1':
          finalResult = finalResult + '១';
          break;
        case '2':
          finalResult = finalResult + '២';
          break;
        case '3':
          finalResult = finalResult + '៣';
          break;
        case '4':
          finalResult = finalResult + '៤';
          break;
        case '5':
          finalResult = finalResult + '៥';
          break;
        case '6':
          finalResult = finalResult + '៦';
          break;
        case '7':
          finalResult = finalResult + '៧';
          break;
        case '8':
          finalResult = finalResult + '៨';
          break;
        case '9':
          finalResult = finalResult + '៩';
          break;
        default:
          finalResult = finalResult + char;
          break;
      }
    });
    return finalResult;
  }
}
